import React from 'react';


class Dashboard extends React.Component {
    
    render() {
        
        return (
            <React.Fragment>
                dashboard
            </React.Fragment>
        );
    }
}

export default Dashboard;