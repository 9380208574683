import React from "react";
import { newsletterService } from "services/NewsletterService.js";
import { userService } from "services/UserService.js";
import { Row, Col, Select, Card, Statistic } from "antd";

class NewsletterStatistics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            newsletterid: this.props.newsletterId || 0,
            newsletters: [],
            userid: this.props.userId || "",
            title: this.props.title || "",
            data: null,
            stats: {
                processed: 0,
                bounced: 0,
                clicked: 0,
                deferred: 0,
                delivered: 0,
                dropped: 0,
                opened: 0,
                spamReported: 0,
            },
        };
        this.authUser = userService.getUserInfo();
    }

    // init the page
    componentDidMount() {
        this.loadData();
    }

    // load up the data
    loadData = () => {
        this.setState({ loading: true });

        // user newsletter, only one
        if (this.state.newsletterid > 0 && this.state.userid !== "") {
            newsletterService
                .getById(this.state.newsletterid, this.state.userid)
                .then((data) => {
                    this.setState({
                        data: data,
                        stats: {
                            processed: data.processed,
                            bounced: data.bounced,
                            clicked: data.clicked,
                            deferred: data.deferred,
                            delivered: data.delivered,
                            dropped: data.dropped,
                            opened: data.opened,
                            spamReported: data.spamReported,
                        },
                    });
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.setState({ loading: false });
                });
            return;
        }

        // admin
        newsletterService
            .get(this.authUser.portalId)
            .then((data) => {
                let processed = data.map((o) => o.processed).reduce((prev, curr) => prev + curr, 0);
                let bounced = data.map((o) => o.bounced).reduce((prev, curr) => prev + curr, 0);
                let clicked = data.map((o) => o.clicked).reduce((prev, curr) => prev + curr, 0);
                let deferred = data.map((o) => o.deferred).reduce((prev, curr) => prev + curr, 0);
                let delivered = data.map((o) => o.delivered).reduce((prev, curr) => prev + curr, 0);
                let dropped = data.map((o) => o.dropped).reduce((prev, curr) => prev + curr, 0);
                let opened = data.map((o) => o.opened).reduce((prev, curr) => prev + curr, 0);
                let spamReported = data.map((o) => o.spamReported).reduce((prev, curr) => prev + curr, 0);

                this.setState(
                    {
                        data: data,
                        newsletters: data.map((o, i) => {
                            return { label: o.name, value: o.id };
                        }),
                        stats: {
                            processed,
                            bounced,
                            clicked,
                            deferred,
                            delivered,
                            dropped,
                            opened,
                            spamReported,
                        },
                    },
                    () => {
                        // preselect if trying to preload one in particular
                        if (this.props.newsletterId > 0) this.applyFilter(this.props.newsletterId);
                    }
                );
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    applyFilter = (val) => {
        if (val === 0) return;

        const { data } = this.state;

        // if filtering to a specific newsletter
        if (val !== "") {
            let n = data.find((o) => o.id === val);

            this.setState({
                title: n.name,
                stats: {
                    processed: n.processed,
                    bounced: n.bounced,
                    clicked: n.clicked,
                    deferred: n.deferred,
                    delivered: n.delivered,
                    dropped: n.dropped,
                    opened: n.opened,
                    spamReported: n.spamReported,
                },
            });
            return;
        }

        // aggregate all newsletters
        let processed = data.map((o) => o.processed).reduce((prev, curr) => prev + curr, 0);
        let bounced = data.map((o) => o.bounced).reduce((prev, curr) => prev + curr, 0);
        let clicked = data.map((o) => o.clicked).reduce((prev, curr) => prev + curr, 0);
        let deferred = data.map((o) => o.deferred).reduce((prev, curr) => prev + curr, 0);
        let delivered = data.map((o) => o.delivered).reduce((prev, curr) => prev + curr, 0);
        let dropped = data.map((o) => o.dropped).reduce((prev, curr) => prev + curr, 0);
        let opened = data.map((o) => o.opened).reduce((prev, curr) => prev + curr, 0);
        let spamReported = data.map((o) => o.spamReported).reduce((prev, curr) => prev + curr, 0);

        this.setState({
            stats: {
                processed,
                bounced,
                clicked,
                deferred,
                delivered,
                dropped,
                opened,
                spamReported,
            },
        });
    };

    render() {
        return (
            <Card
                title={this.state.title}
                loading={this.state.loading}
                bordered={false}
                extra={
                    <div className="d-flex">
                        {Array.isArray(this.state.data) && (
                            <Select style={{ width: 300 }} defaultValue={this.state.newsletterid} onChange={this.applyFilter}>
                                <Select.Option value={0}>All Newsletters</Select.Option>
                                {this.state.newsletters.map((o, i) => {
                                    return (
                                        <Select.Option key={i} value={o.value}>
                                            {o.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </div>
                }
            >
                {this.state.data && (
                    <Row gutter={16}>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Processed" value={this.state.stats.processed} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Delivered" value={this.state.stats.delivered} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Opens" value={this.state.stats.opened} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Clicks" value={this.state.stats.clicked} />
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Dropped" value={this.state.stats.dropped} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Bounced" value={this.state.stats.bounced} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Deferred" value={this.state.stats.deferred} />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card className="m-1">
                                <Statistic title="Spam Reports" value={this.state.stats.spamReports} />
                            </Card>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    }
}

export default NewsletterStatistics;
