import React from "react";
import { Spin, Button, Card } from "antd";
import { newsletterService } from "services/NewsletterService";
import { userService } from "services/UserService.js";
import NewsletterStatistics from "components/charts/NewsletterStatistics";

class NewsletterStats extends React.Component {
    constructor(props) {
        super(props);

        // get user info
        this.authUser = userService.getUserInfo();

        this.state = {
            id: 0,
            userid: null,
            loading: true,
            data: null,
        };
    }

    componentDidMount() {
        let { id, userid } = this.props.match.params;
        // get the data for this id
        if (id !== undefined) {
            id = parseInt(id, 10);

            this.setState(
                {
                    id: id,
                    userid: userid,
                },
                this.loadData
            );
        }
    }

    loadData = () => {
        // if no id, then leave
        if (this.state.id === 0) this.props.history.push("/manage/dashboard");

        this.setState({
            loading: true,
        });

        // get stats for this one
        newsletterService
            .getById(this.state.id, this.state.userid)
            .then((data) => {
                this.setState({
                    data: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        if (this.state.loading || this.state.data === null) {
            return <Spin size="large" />;
        } else {
            return (
                <Card
                    style={{ width: "100%" }}
                    title="Newsletter"
                    extra={
                        <Button type="primary" icon="backward" onClick={() => this.props.history.goBack()}>
                            Back
                        </Button>
                    }
                >
                    <NewsletterStatistics title={this.state.data.name} newsletterId={this.state.id} userId={this.state.data.userId} />
                </Card>
            );
        }
    }
}

export default NewsletterStats;
