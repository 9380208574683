import { userService } from "services/UserService";

// whenever the user needs a refresh(new social settings)
export function getUser(id) {
    userService
        .getById(id)
        .then((data) => {
            // console.log(data);

            // copy over the token
            let token = userService.getUserInfo().token;
            data.token = token;

            // resave the new data
            localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(data))));
            return data;
        })
        .catch((err) => {
            return null;
        });
}

// coming from the login (oauth.js)
export function setUser(user) {
    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(user))));
}
