
import React from 'react';
import { Upload, Icon, notification } from 'antd';
import { userService } from 'services/UserService.js';
import { Messaging } from 'util/LangProvider/locales/en_US_notifications';

const { Dragger } = Upload;

function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        notification.error({
            message: 'Upload Error',
            description: 'File must smaller than 2MB'
        });
    }
    return isLt2M;
}

class FileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        // get user info
        this.authUser = userService.getUserInfo();
    }

    handleChange = info => {

        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }

        if (info.file.status === 'done') {           

            /*
            notification.success({
                message: Messaging.success(),
                description: `${info.file.name} file uploaded successfully.`
            });
            */

            // alert the parent with the url of the uploaded file (maybe handle multiples in the future)
            if(this.props.onUploaded)
                this.props.onUploaded(info.file.response);

        } else if (info.file.status === 'error') {
            notification.error({
                message: Messaging.error(),
                description: `${info.file.name} file upload failed.`
            });
        }
    };

  render() {

    const headers = {
        'Authorization': 'Bearer ' + this.authUser.token,
    }
    return (
        <div>
            <Dragger 
                name='files'
                accept={this.props.accept}
                multiple={false}
                beforeUpload={beforeUpload}
                action={`${process.env.REACT_APP_WEBAPI_URL}/api/media/files`}
                headers={headers}
                onChange={this.handleChange}>
                <p className="ant-upload-drag-icon">
                    <Icon type="file-add" />
                </p>
                <p className="ant-upload-text">Click or drag files to this area to upload</p>
                <p className="ant-upload-hint">must be only csv or txt files</p>
            </Dragger>
        </div>
    );
  }
}

export default FileUploader;
          