import React, { Component } from "react";
import { Tabs, Row, Col } from "antd";
// import { Messaging } from 'util/LangProvider/locales/en_US_notifications';
import UserProfileInfo from "./UserProfileInfo";
import UserRoles from "./UserRoles";
import UserLeads from "./UserLeads";
import UserNewsletter from "./UserNewsletter";
import UserNewsletters from "./UserNewsletters";

const { TabPane } = Tabs;

class UserProfile extends Component {
    render() {
        console.log(window.location.hash);
        let defTab = window.location.hash === "#clientlist" ? 4 : 1;

        return (
            <div>
                <Row className="mb-4">
                    <Col span={6}>
                        <img src={this.props.data.thumbnailUrl} alt={this.props.data.fullName} className="w-80" />
                    </Col>
                    <Col span={18}>
                        <h4 className="mb-0">{this.props.data.fullName}</h4>
                        <p className="sub-heading mb-0">{this.props.data.accountType}</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Tabs defaultActiveKey={defTab.toString()}>
                            <TabPane tab="Profile" key="1">
                                <UserProfileInfo data={this.props.data} />
                            </TabPane>

                            <TabPane tab="Roles" key="2">
                                <UserRoles data={this.props.data} />
                            </TabPane>

                            <TabPane tab="Newsletter Settings" key="3">
                                <UserNewsletter data={this.props.data} />
                            </TabPane>

                            <TabPane tab="Client List" key="4">
                                <UserLeads data={this.props.data} isAdmin={true} />
                            </TabPane>

                            <TabPane tab="Newsletters" key="5">
                                <UserNewsletters data={this.props.data} isAdmin={true} />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UserProfile;
