import axios from "axios";
import { authHeader } from "util/AuthHeader";
import { handleResponse } from "util/HandleResponse";

// define public methods
export const newsletterService = {
    get,
    getById,
    post,
    deleteItem,
    test,
    resend,
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem("user_info");
            window.location.reload(true);
        }
        return Promise.reject(error);
    }
);

function get(portalid, userid, autoonly) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/newsletter?portalid=${portalid}`;

        if (userid !== undefined) url += `&userid=${userid}`;

        if (autoonly !== undefined) url += `&autoonly=${autoonly}`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Newsletter not found");
            });
    });
}

function getById(id, userid) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = !!!userid ? `${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/${id}` : `${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/${userid}/${id}`;
        console.log(url);
        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function post(data) {
    const requestOptions = { headers: authHeader() };

    // add new
    if (data.id === 0) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_WEBAPI_URL}/api/newsletter`, data, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let post = res.data;
                    resolve(post);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    // update
    return new Promise((resolve, reject) => {
        axios
            .put(`${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/` + data.id, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                resolve(data);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function deleteItem(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios
            .delete(`${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/${id}`, requestOptions)
            .then(handleResponse)
            .then((res) => {
                resolve(id);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function test(id, userid, useList, arrayOfEmails) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let data = {
            emails: arrayOfEmails,
            userid: userid,
            newsletterid: id,
            useList: useList,
        };

        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/publish`;

        axios
            .post(url, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function resend(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/newsletter/${id}/resend`;

        axios
            .put(url, null, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}
