import React from "react";
import IntlMessages from "util/IntlMessages";
import { userService } from "services/UserService";
import qs from "qs";
import { LOGIN_URL } from "util/Configs/LoginConfig";
import { setUser } from "util/UserUtils";
import Layout from "components/SimpleLayout";
import { Card, Spin, Alert } from "antd";

class Oauth extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            errReason: "",
        };
    }

    componentDidMount() {
        // make sure we have the code
        let q = qs.parse(window.location.search.replace("?", ""));

        // if no value, then go home
        // if(q.code === "" || q.code === undefined)
        // this.props.history.push ('/');
        console.log(q.code);
        const alias = window.location.port ? window.location.hostname + ":" + window.location.port : window.location.hostname;
        // get the user's info
        userService
            .verify(alias, q.code)
            .then((data) => {
                this.handleLogin(data);
            })
            .catch((err) => {
                console.log(err, err.response);
                if (err.response !== undefined) {
                    this.setState({
                        errReason: err.response.data ?? "Unknown error",
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    handleLogin(data) {
        this.setState({
            isloading: true,
        });

        setUser(data);

        window.location.href = "/"; // go home
    }

    render() {
        return (
            <Layout>
                <Card className="w-50 m-auto">
                    {this.state.loading && (
                        <div className="text-center">
                            <h2>
                                <a href={LOGIN_URL}>Logging you in...</a>
                            </h2>

                            <Spin size="large" />
                        </div>
                    )}

                    {!this.state.loading && this.state.errReason === "" && (
                        <div className="text-center">
                            <h2>Loading your profile...</h2>

                            <Spin size="large" />
                        </div>
                    )}

                    {!this.state.loading && this.state.errReason !== "" && (
                        <div className="p-3">
                            <div className="app-login-header mb-4">
                                <h1>
                                    <IntlMessages id="noaccess.title" />
                                </h1>
                            </div>

                            <Alert message={<IntlMessages id="noaccess.blurb" />} description={`Reason: ${this.state.errReason}`} type="error" showIcon />

                            <div className="row mt-3">
                                <div className="col-6 text-center mt-3">
                                    <p>
                                        <a href="http://ideafit.com" className="btn btn-primary">
                                            <IntlMessages id="noaccess.ideafit" />
                                        </a>
                                    </p>
                                </div>
                                <div className="col-6 text-center mt-3">
                                    <p>
                                        <a href={LOGIN_URL} className="btn btn-light">
                                            <IntlMessages id="noaccess.button" />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </Card>
            </Layout>
        );
    }
}

export default Oauth;
