import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Layout from 'components/MasterLayout';

import Dashboard from './dashboard';
import Newsletters from './newsletters';
import Newsletter from './newsletter';
import Leads from './leads';
import Lead from './lead';
import Settings from './settings';
import GettingStarted from './gettingstarted';
import NewsletterStats from './newsletterstats';

import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from 'util/AsyncComponent';

class MainApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: true
        }
    }

    render() {
        const {match} = this.props;

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        
        return (
            <Layout>

                <Switch>
                    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                    <Route path={`${match.url}/newsletters`} component={Newsletters}/>
                    <Route path={`${match.url}/newsletter/:id`} component={Newsletter}/>
                    <Route path={`${match.url}/newsletter`} component={Newsletter}/>
                    <Route path={`${match.url}/leads`} component={Leads}/>
                    <Route path={`${match.url}/lead/:id`} component={Lead}/>
                    <Route path={`${match.url}/lead`} component={Lead}/>
                    <Route path={`${match.url}/settings`} component={Settings}/>
                    <Route path={`${match.url}/gettingstarted`} component={GettingStarted}/>
                    <Route path={`${match.url}/newsletterstats/:id`} component={NewsletterStats}/>
                    <Route component={asyncComponent(() => import('pages/misc/404'))}/>
                </Switch>
                
                <NotificationContainer/>      

            </Layout>
        );
    }
}

export default MainApp;