import axios from 'axios';
import { authHeader } from 'util/AuthHeader';
import { handleResponse } from 'util/HandleResponse';

// define public methods
export const templateService = {
    get,
    getById,
    post,
    deleteItem
};

axios.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === 401) {
            localStorage.removeItem('user_info');
            window.location.reload(true);
        }
        return Promise.reject(error);
   }
);

function get(portalid){
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/template?portalid=${portalid}`;

        axios.get(url, requestOptions)
            .then(handleResponse)
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            });
    });
}

function getById(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/template/${id}`;
            
        axios.get(url, requestOptions)
            .then(handleResponse)
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            });
    });
}

function post(data) {

    const requestOptions = { headers: authHeader() };
    
    // add new
    if(data.id === 0) {
        return new Promise((resolve, reject) => {

            axios.post(`${process.env.REACT_APP_WEBAPI_URL}/api/template`, data, requestOptions)
                .then(handleResponse)
                .then(res => {
                    let post = res.data;
                    resolve(post);
                })
                .catch(res => {
                    reject('Error');
                })
        })
    }

    // update
    return new Promise((resolve, reject) => {
    
        axios.put(`${process.env.REACT_APP_WEBAPI_URL}/api/template/` + data.id, data, requestOptions)
            .then(handleResponse)
            .then(res => {                    
                resolve(data);
            })
            .catch(res => {
                reject('Error');
            });
    });        
}

function deleteItem(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_WEBAPI_URL}/api/template/${id}`, requestOptions)
            .then(handleResponse)
            .then(res => {                    
                resolve(id);
            })
            .catch(res => {
                reject('Error');
            });
    });
}