import React from "react";
import { Route, Switch } from "react-router-dom";

import Layout from "components/MasterLayout";

import Dashboard from "./dashboard";
import Newsletters from "./newsletters";
import Newsletter from "./newsletter";
import Templates from "./templates";
import Template from "./template";
import Users from "./users";
import User from "./user";
import Lead from "./lead";
import NewsletterStats from "./newsletterstats";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "util/AsyncComponent";

class ManageApp extends React.Component {
    render() {
        const { match } = this.props;

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add("ios-mobile-view-height");
        } else if (document.body.classList.contains("ios-mobile-view-height")) {
            document.body.classList.remove("ios-mobile-view-height");
        }

        return (
            <Layout>
                <Switch>
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/newsletters`} component={Newsletters} />
                    <Route path={`${match.url}/newsletter/:id?`} component={Newsletter} />
                    <Route path={`${match.url}/templates`} component={Templates} />
                    <Route path={`${match.url}/template/:id?`} component={Template} />
                    <Route path={`${match.url}/users`} component={Users} />
                    <Route path={`${match.url}/user/:id`} component={User} />
                    <Route path={`${match.url}/lead/:userid/:id?`} component={Lead} />
                    <Route path={`${match.url}/newsletterstats/:id/:userid?`} component={NewsletterStats} />
                    <Route component={asyncComponent(() => import("pages/misc/404"))} />
                </Switch>

                <NotificationContainer />
            </Layout>
        );
    }
}

export default ManageApp;
