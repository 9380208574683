import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Card, Alert, Spin, Button } from "antd";
import { newsletterService } from "services/NewsletterService";
import { userService } from "services/UserService.js";
import { userNewsletterService } from "services/UserNewsletterService.js";
// import { Messaging } from 'util/LangProvider/locales/en_US_notifications';
import moment from "moment";
import IntroVideo from "components/ui/IntroVideo";

class Newsletter extends React.Component {
    constructor(props) {
        super(props);

        // get user info
        this.authUser = userService.getUserInfo();

        this.state = {
            id: 0,
            loading: false,
            searchVisible: false,
            content: [],
            name: "",
            templateId: 0,
            template: [],
            outputHtml: "",
            isPublished: false,
            datePublish: null,
            description: "",
            newsletterLogo: "",
            newsletterName: "",
            profileImage: "",
            websiteUrl: "",
            newsletterBody: "",
            facebookUrl: "",
            linkedInUrl: "",
            twitterUrl: "",
            instagramUrl: "",
            showSettingsBug: false,
            showEmpty: false,
            useProfilePhoto: true,
        };
    }

    componentDidMount() {
        // see if we're prepopulating
        this.getExistingItem();
    }

    getExistingItem = () => {
        this.setState({
            loading: true,
        });

        // get id if any
        let { id } = this.props.match.params;
        // update - get the data for this id
        if (id !== undefined) {
            id = parseInt(id, 10);
            this.setState({
                id: id,
            });
        } else {
            // otherwise, get the upcoming pending item
            id = this.state.id; // 0
        }

        Promise.all([newsletterService.getById(id, this.authUser.id), userNewsletterService.get(this.authUser.id)])
            .then((response) => {
                let n = response[0];
                let un = response[1];

                this.setState({
                    datePublish: moment(n.datePublish),
                    isPublished: n.isPublished,
                    templateId: n.templateId,
                    template: n.template,
                    outputHtml: n.template.body,
                    content: JSON.parse(n.postJson),
                    name: n.name,
                    newsletterName: un.newsletterName,
                    newsletterLogoType: parseInt(un.newsletterLogoType, 10),
                    newsletterLogo: un.newsletterLogo,
                    websiteUrl: un.websiteUrl,
                    newsletterBody: un.newsletterBody,
                    facebookUrl: un.facebookUrl,
                    linkedInUrl: un.linkedInUrl,
                    twitterUrl: un.twitterUrl,
                    instagramUrl: un.instagramUrl,
                    showSettingsBug: un.userId === null,
                    useProfilePhoto: un.useProfilePhoto,
                });

                // show the content
                this.transformBody();
            })
            .catch((err) => {
                this.setState({
                    showEmpty: true,
                });
                /*
            notification.error({
                message: Messaging.error(),
                description: err
            });*/
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    transformPosts = (posts) => {
        let transformed = posts.map((o, idx) => {
            let p =
                "<table border='0' cellpadding='0' cellspacing='0' style='margin-top:10px; margin-bottom:10px; border-bottom:dotted 1px #cccccc;' width='500'><tr>" +
                "<td valign='top'><a href='%%POST_LINK%%' target='_blank' ><img border='0' src='%%POST_IMAGE%%' style='padding:0 0 15px 0;' width='100' /></a></td>" +
                "<td id='news_article_content' style='font-size:14px; color:#595959; font-family:Arial, Helvetica, sans-serif; padding-left: 15px; padding-right:20px;' valign='top'>" +
                "<a href='%%POST_LINK%%' style='color:#336699; text-decoration:none; font-size:16px;' target='_blank' >%%POST_TITLE%%</a>" +
                "<p>%%POST_BODY%%</p>" +
                "<a href='%%POST_LINK%%' style='text-decoration:none; color:#336699;' target='_blank' >read more &raquo;</a><br />&nbsp;</td>" +
                "</tr></table>";

            // replacements
            p = p.replace("%%POST_TITLE%%", o.title);
            p = p.replace("%%POST_BODY%%", o.body);
            p = p.replace("%%POST_IMAGE%%", o.thumbUrl);
            p = p.replace(/%%POST_LINK%%/g, o.postUrl);

            return p;
        });

        return transformed.join(" ");
    };

    transformSocial = () => {
        const { facebookUrl, linkedInUrl, twitterUrl, instagramUrl } = this.state;
        let s = "";
        https: if (facebookUrl && facebookUrl !== "") s += `<a href='${facebookUrl}'><img src='https://clientnewsapp.blob.core.windows.net/images/icon-facebook.png' width='24' /></a>&nbsp;`;
        if (linkedInUrl && linkedInUrl !== "") s += `<a href='${linkedInUrl}'><img src='https://clientnewsapp.blob.core.windows.net/images/icon-linkedin.png' width='24' /></a>&nbsp;`;
        if (twitterUrl && twitterUrl !== "") s += `<a href='${twitterUrl}'><img src='https://clientnewsapp.blob.core.windows.net/images/icon-twitter.png' width='24' /></a>&nbsp;`;
        if (instagramUrl && instagramUrl !== "") s += `<a href='${instagramUrl}'><img src='https://clientnewsapp.blob.core.windows.net/images/icon-instagram.png' width='24' /></a>`;
        return s;
    };

    transformBody = () => {
        let b = this.state.outputHtml;

        // logo
        let logo = ""; // type == 2
        if (this.state.newsletterLogoType === 0) logo = "https://www.ideafit.com/wp-content/uploads/2023/10/idea-logo-300px.png";
        if (this.state.newsletterLogoType === 1) logo = this.state.newsletterLogo;
        b = b.replace("%%SENDER_LOGO%%", logo && logo !== "" ? "<img src='" + logo + "' style='max-width:120px;margin:40px 0' />" : "");

        // blurb
        b = b.replace("%%SENDER_TITLE%%", this.state.newsletterName && this.state.newsletterName !== "" ? this.state.newsletterName : "");
        b = b.replace("%%SENDER_INTRO%%", this.state.newsletterBody && this.state.newsletterBody !== "" ? this.state.newsletterBody : "");

        // date / issue
        b = b.replace("%%NAME%%", this.state.name && this.state.name !== "" ? this.state.name : "");

        // content
        b = b.replace("%%CONTENT%%", this.transformPosts(this.state.content));

        // profile
        b = b.replace("%%SENDER_AVATAR%%", this.state.useProfilePhoto && this.authUser.thumbnailUrl !== null ? "<img src='" + this.authUser.thumbnailUrl + "' width='200' />" : "");
        b = b.replace("%%SENDER_NAME%%", this.authUser.firstName + " " + this.authUser.lastName);

        // website
        b = b.replace(
            "%%SENDER_WEBSITE_URL%%",
            this.state.websiteUrl !== null && this.state.websiteUrl !== ""
                ? "<a href='" + this.state.websiteUrl + "'>" + this.state.websiteUrl.replace("https://", "").replace("http://", "") + "</a>"
                : ""
        );

        // social
        b = b.replace("%%SENDER_SOCIAL%%", this.transformSocial());

        // unsubscribe link
        b = b.replace(/%%UNSUBSCRIBE_URL%%/g, `${process.env.REACT_APP_WEBAPI_URL}/unsubscribe`);

        // replace all links to open _blank
        b = b.replace(/<a /g, "<a target='_blank' ");

        this.setState({
            outputHtml: b,
        });
    };

    /*
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);

                // create the object
                values.id = this.state.id;
                values.templateId = parseInt(values.templateId, 10);
                values.isAuto = true;
                values.isPublished = false;
                values.postJson = JSON.stringify(this.state.content);
                values.portalId = this.authUser.portalId;
                values.updatedBy = this.authUser.id;

                this.setState({
                    loading: true
                });

                newsletterService.post(values)
                    .then(data => {
                        console.log(data);
                        
                        this.props.history.push('/app/newsletters');
                    })
                    .catch(err => {
                        console.log(err);

                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        });
                    });

            }
        });
    }; */

    render() {
        if (this.state.loading) {
            return (
                <Card className="w-50 m-auto text-center">
                    <Spin size="large" />
                </Card>
            );
        } else if (this.state.showEmpty) {
            return <Alert message="Please come back soon" description="No newsletters are currently ready to send." type="info" showIcon className="w-50 mb-2 mx-auto text-center" />;
        } else {
            let backBtn =
                this.state.id > 0 ? (
                    <Button type="primary" icon="backward" onClick={() => this.props.history.goBack()}>
                        Back
                    </Button>
                ) : (
                    <span></span>
                );

            return (
                <div>
                    {this.state.showSettingsBug && (
                        <NavLink to="/app/settings">
                            <Alert message={`Update your settings`} description={`to personalize your newsletter`} type="warning" showIcon className="w-50 mb-2 mx-auto text-center" />
                        </NavLink>
                    )}
                    {!this.state.isPublished && (
                        <Alert
                            message={`Scheduled for publishing on ${moment(this.state.datePublish).format("LL")}`}
                            description={`please have any updates complete by the end of ${moment(this.state.datePublish).subtract(2, "days").format("LL")}`}
                            type="info"
                            showIcon
                            className="w-50 mb-2 mx-auto text-center"
                        />
                    )}
                    {this.state.isPublished && <Alert message={`Published on ${moment(this.state.datePublish).format("LL")}`} type="success" showIcon className="w-50 mb-2 mx-auto text-center" />}
                    <Card style={{ width: "100%" }} title={this.state.name} extra={backBtn} bordered={false}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.outputHtml }}></div>
                    </Card>

                    <IntroVideo useModal={true} />
                </div>
            );
        }
    }
}

const WrappedForm = Form.create({ name: "newsletterForm" })(Newsletter);
export default WrappedForm;
