import React from "react";
import IntlMessages from "util/IntlMessages";
import { userService } from "services/UserService.js";
import { Table, Button, Card, Input, Tag } from "antd";

const { Search } = Input;

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            users: [],
            pages: 0,
            keyword: "",
            roleid: 0,
            pagination: { current: 1, pageSize: 15 },
            gridview: true,
        };

        this.authUser = userService.getUserInfo();
    }

    componentDidMount() {
        // initla load of the table
        this.loadGrid(this.state.pagination.current, this.state.pagination.pageSize, [{ id: "lastName", desc: false }], this.state.keyword);
    }

    loadGrid = (page, pagesize, sorted, filters) => {
        this.setState({
            loading: true,
        });

        userService
            .get(this.authUser.portalId, page, pagesize, sorted, filters)
            .then((data) => {
                const pager = { ...this.state.pagination };
                pager.total = data.totalRecords;

                this.setState({
                    users: data.users,
                    pagination: pager,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });

        this.loadGrid(pagination.current, pagination.pageSize, [{ id: sorter.field, desc: sorter.order }], this.state.keyword);
    };

    handleFieldChange = (evt) => {
        this.setState({ [evt.target.id]: evt.target.value });
    };

    handleSearch = (keyword) => {
        this.loadGrid(this.state.pagination.current, this.state.pagination.pageSize, [{ id: "lastName", desc: false }], this.state.keyword);
    };

    toggleProfile = (id) => {
        this.setState({
            gridview: !this.state.gridview,
        });
    };

    render() {
        let cancelBtn = this.state.gridview ? (
            ""
        ) : (
            <Button color="btn btn-light" onClick={this.toggleProfile} title="Go Back">
                Go Back
            </Button>
        );

        return (
            <Card title={<IntlMessages id="manage.users.title" />} extra={<span>{cancelBtn}</span>}>
                <div>
                    <Search
                        id="keyword"
                        placeholder="search"
                        onSearch={this.handleSearch}
                        enterButton
                        value={this.state.keyword}
                        onChange={this.handleFieldChange}
                        className="mb-3 w-30 float-right"
                        loading={this.state.loading}
                    />

                    <Table
                        dataSource={this.state.users}
                        rowKey={(x) => x.id}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        size="middle"
                        className="clear-both pointer"
                        onChange={this.handleTableChange}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (e) => {
                                    this.props.history.push(`/manage/user/${record.id}`);
                                },
                            };
                        }}
                        columns={[
                            {
                                title: "",
                                dataIndex: "thumbnailUrl",
                                width: "6%",
                                render: (text, record) => <img src={`${record.thumbnailUrl}`} alt="Thumbnail" className="img-fluid" />,
                            },
                            {
                                title: "First",
                                dataIndex: "firstName",
                                sorter: true,
                                filterMultiple: false,
                            },
                            {
                                title: "Last",
                                dataIndex: "lastName",
                                sorter: true,
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                                sorter: true,
                            },
                            {
                                title: "Membership",
                                dataIndex: "membershipCategory",
                            },
                            {
                                title: "Status",
                                dataIndex: "status",
                                sorter: true,
                                render: (text, record) => (
                                    <span>
                                        {record.status === "active" && <Tag color="green">{record.status}</Tag>}
                                        {record.status !== "active" && <Tag color="volcano">{record.status}</Tag>}
                                    </span>
                                ),
                            },
                            {
                                title: "Roles",
                                dataIndex: "userRoles.roleId",
                                sorter: false,
                                render: (text, record) => (
                                    <span>
                                        {record.userRoles.map((o, idx) => {
                                            return (
                                                <Tag key={idx} color="blue" className="mb-1">
                                                    {o.roleName}
                                                </Tag>
                                            );
                                        })}
                                    </span>
                                ),
                            },
                        ]}
                    />
                </div>
            </Card>
        );
    }
}

export default Users;
