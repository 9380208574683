import React, {Component} from 'react';
import { Form, Input } from 'antd';
// import { Messaging } from 'util/LangProvider/locales/en_US_notifications';


class UserProfileInfo extends Component {
    
    componentDidMount() {
        this.props.form.setFieldsValue({
            firstName: this.props.data.firstName,
            lastName: this.props.data.lastName,
            email: this.props.data.email,
            status: this.props.data.status,
            timeZone: this.props.data.timeZone
        });
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div>

                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>

                    <Form.Item label="First">
                        {getFieldDecorator('firstName', {
                            rules: [{ required: false, message: 'Please give your newsletter a title' }],
                        })(<Input placeholder="First Name" />)}
                    </Form.Item>

                    <Form.Item label="Last">
                        {getFieldDecorator('lastName', {
                            rules: [{ required: false, message: 'Please enter a valid URL' }],
                        })(<Input placeholder="Last Name" />)}
                    </Form.Item>

                    <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{ required: false, message: 'Please enter your facebook profile URL' }],
                        })(<Input placeholder="email" />)}
                    </Form.Item>

                    <Form.Item label="Status">
                        {getFieldDecorator('status')(<Input placeholder="status" disabled />)}
                    </Form.Item>

                    <Form.Item label="Time Zone">
                        {getFieldDecorator('timeZone')(<Input placeholder="time zone" disabled />)}
                    </Form.Item>

                </Form>

            </div>
        )
    }


}

const WrappedForm = Form.create({ name: 'userProfileForm' })(UserProfileInfo);
export default WrappedForm;