
import React from 'react';
import { Upload, Icon, notification } from 'antd';
import { userService } from 'services/UserService.js';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        notification.error({
            message: 'Upload Error',
            description: 'You can only upload JPG/PNG files'
        });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        notification.error({
            message: 'Upload Error',
            description: 'Image must smaller than 2MB'
        });
    }
    return isJpgOrPng && isLt2M;
}

class Uploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        // get user info
        this.authUser = userService.getUserInfo();
    }    

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }

        if (info.file.status === 'done') {            
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }, () => { // pass the actual URL back to the calling component
                    if(this.props.handleUploadSuccess)
                        this.props.handleUploadSuccess(info.file.response);
                }),
               
            );
        }
    };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let img = (this.props.src != null && this.props.src.length > 0)
    ? <img src={this.props.src} className="img-fluid" alt="thumbnail" style={{maxWidth:300}} />
    : uploadButton;

    const headers = {
        'Authorization': 'Bearer ' + this.authUser.token,
    }

    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
        action={`${process.env.REACT_APP_WEBAPI_URL}/api/media?maxWidth=250`}
        headers={headers}
      >
        {img}
      </Upload>
    );
  }
}

export default Uploader;
          