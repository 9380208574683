import React from "react";
import { Form, Input, Button, Card, Switch } from "antd";
import { templateService } from "services/TemplateService";
import { userService } from "services/UserService.js";

class Template extends React.Component {
    constructor(props) {
        super(props);

        // get user info
        this.authUser = userService.getUserInfo();

        this.state = {
            id: 0,
            loading: false,
        };
    }

    componentDidMount() {
        // get id if any
        let { id } = this.props.match.params;

        // update - get the data for this id
        if (id !== undefined) {
            id = parseInt(id, 10);
            this.setState({
                id: id,
            });

            this.setState({
                loading: true,
            });

            templateService
                .getById(id)
                .then((data) => {
                    this.props.form.setFieldsValue({
                        name: data.name,
                        body: data.body,
                        isActive: data.isActive,
                        isDefault: data.isDefault,
                    });
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // create the object
                values.id = this.state.id;
                values.portalId = this.authUser.portalId;
                values.updatedBy = this.authUser.id;

                this.setState({
                    loading: true,
                });

                templateService
                    .post(values)
                    .then((data) => {
                        this.props.history.push("/manage/templates");
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Card
                style={{ width: "100%" }}
                title="Template"
                extra={
                    <Button type="primary" icon="backward" onClick={() => this.props.history.goBack()}>
                        Back
                    </Button>
                }
            >
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                    <Form.Item label="Title">
                        {getFieldDecorator("name", {
                            rules: [{ required: true, message: "Please give it a title" }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Template Body">
                        {getFieldDecorator("body", {
                            rules: [{ required: true, message: "Please input some code" }],
                        })(<Input.TextArea rows={10} />)}
                    </Form.Item>

                    <Form.Item label="Active">{getFieldDecorator("isActive", { valuePropName: "checked" })(<Switch />)}</Form.Item>

                    <Form.Item label="Default">{getFieldDecorator("isDefault", { valuePropName: "checked" })(<Switch />)}</Form.Item>

                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

const WrappedForm = Form.create({ name: "templateForm" })(Template);
export default WrappedForm;
