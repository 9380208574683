import React from "react";
import { Link } from "react-router-dom";
import { LOGIN_URL } from "util/Configs/LoginConfig";
import { userService } from "services/UserService.js";
import Layout from "components/SimpleLayout";
import { Card, Spin } from "antd";

class ThirdPartySignIn extends React.Component {
    constructor(props) {
        super(props);

        // see if they have an account
        this.authUser = userService.getUserInfo();

        if (this.authUser === null) {
            window.location.href = LOGIN_URL;
        }
    }

    render() {
        if (this.authUser === null) {
            return (
                <Layout>
                    <Card className="w-50 m-auto text-center">
                        <h2>
                            <a href={LOGIN_URL}>Forwarding to the sign in screen...</a>
                        </h2>
                        <Spin size="large" />
                    </Card>
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <Card className="w-50 m-auto text-center">
                        <h2>
                            <a href={LOGIN_URL}>You're already signed in.</a>
                        </h2>
                        <p>
                            <Link className="btn btn-primary" to="/">
                                Go Home
                            </Link>
                        </p>
                    </Card>
                </Layout>
            );
        }
    }
}

export default ThirdPartySignIn;
