import React from "react";
import { Table, Card, Icon, Tag, Tooltip } from "antd";
import { newsletterService } from "services/NewsletterService";
import { userService } from "services/UserService.js";
import moment from "moment";

class Newsletters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pagination: {},
            loading: false,
        };

        this.authUser = userService.getUserInfo();

        this.columns = [
            {
                title: "Name",
                dataIndex: "name",
            },
            {
                title: "Publish Date",
                dataIndex: "datePublish",
                render: (text, record) => <span>{moment(record.datePublish).format("LL")}</span>,
            },
            {
                title: "Published",
                dataIndex: "isPublished",
                align: "center",
                render: (text, record) => (
                    <div className="text-center">
                        {record.isPublished && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
                        {!record.isPublished && record.isSkipped && <Tag color="red">skipped</Tag>}
                        {!record.isPublished && !record.isSkipped && <Tag color="volcano">pending</Tag>}
                    </div>
                ),
            },
            {
                title: "Actions",
                align: "center",
                key: "id",
                render: (text, record) => (
                    <div className="text-center">
                        {!record.isPublished && (
                            <Tooltip title="edit">
                                <span onClick={() => this.handleEdit(record.id)}>
                                    <Icon type="edit" theme="twoTone" />
                                </span>
                            </Tooltip>
                        )}

                        {record.isPublished && (
                            <Tooltip title="view stats">
                                <span className="pointer" onClick={() => this.props.history.push(`/app/newsletterstats/${record.id}`)}>
                                    <Icon type="fund" theme="twoTone" />
                                </span>
                            </Tooltip>
                        )}
                        {/*
                          !record.isDefault && 
                            <span>
                                <Divider type="vertical" />
                                <span onClick={() => this.handleDelete(record.id)}><Icon type="delete" theme="twoTone" /></span>
                            </span>
                        */}
                    </div>
                ),
            },
        ];
    }

    componentDidMount() {
        this.loadGrid();
    }

    loadGrid = (params = {}) => {
        this.setState({
            loading: true,
        });

        newsletterService
            .get(this.authUser.portalId, this.authUser.id, false)
            .then((data) => {
                this.setState({
                    data: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.loadGrid({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    handleAdd = (evt) => {
        this.props.history.push("/app/newsletter");
    };

    handleEdit = (id) => {
        this.props.history.push(`/app/newsletter/${id}`);
    };

    handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to remove this item?")) return;

        newsletterService
            .deleteItem(id)
            .then((data) => {
                this.setState({
                    loading: false,
                });

                // splice out the deleted object
                var idx = this.state.data.findIndex((o) => o.id === id);
                if (idx > -1) {
                    let list = [...this.state.data];
                    list.splice(idx, 1);
                    this.setState({
                        data: list,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        return (
            <Card style={{ width: "100%" }} title="Newsletters" bordered={false}>
                <Table columns={this.columns} rowKey={(x) => x.id} dataSource={this.state.data} pagination={this.state.pagination} loading={this.state.loading} onChange={this.handleTableChange} />
            </Card>
        );
    }
}

export default Newsletters;
