import React from 'react';
import Layout from 'components/SimpleLayout';
import { Card } from 'antd';


class Unsubscribe extends React.Component {

    render () {
        
        return (
            <Layout>
                <Card className="w-50 m-auto text-center">                
                    <h2>You have been unsubscribed.</h2>
                    <a className="btn btn-primary" href="https://www.ideafit.com">Go to Ideafit</a>
                </Card>
            </Layout>
        );
    
    }
}

export default Unsubscribe;