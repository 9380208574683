import React from "react";
import { Button, Table, Divider, Card, Icon } from "antd";
import { templateService } from "services/TemplateService";
import { userService } from "services/UserService.js";

class Templates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pagination: {},
            loading: false,
        };

        this.authUser = userService.getUserInfo();

        this.columns = [
            {
                title: "Name",
                dataIndex: "name",
                width: "20%",
            },
            {
                title: "Active",
                dataIndex: "isActive",
                align: "center",
                width: "20%",
                render: (text, record) => (
                    <div className="text-center">
                        {record.isActive && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
                        {!record.isActive && <Icon type="stop" theme="twoTone" twoToneColor="#aa3333" />}
                    </div>
                ),
            },
            {
                title: "Default",
                dataIndex: "isDefault",
                align: "center",
                render: (text, record) => (
                    <div className="text-center">
                        {record.isDefault && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
                        {!record.isDefault && <Icon type="stop" theme="twoTone" twoToneColor="#aa3333" />}
                    </div>
                ),
            },
            {
                title: "Actions",
                align: "left",
                key: "id",
                render: (text, record) => (
                    <div className="text-left">
                        <span onClick={() => this.handleEdit(record.id)}>
                            <Icon type="edit" theme="twoTone" />
                        </span>

                        {!record.isDefault && (
                            <span>
                                <Divider type="vertical" />
                                <span onClick={() => this.handleDelete(record.id)}>
                                    <Icon type="delete" theme="twoTone" />
                                </span>
                            </span>
                        )}
                    </div>
                ),
            },
        ];
    }

    componentDidMount() {
        this.loadGrid();
    }

    loadGrid = (params = {}) => {
        this.setState({
            loading: true,
        });

        templateService
            .get(this.authUser.portalId)
            .then((data) => {
                this.setState({
                    data: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.loadGrid({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    handleAdd = (evt) => {
        this.props.history.push("/manage/template");
    };

    handleEdit = (id) => {
        this.props.history.push(`/manage/template/${id}`);
    };

    handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to remove this item?")) return;

        templateService
            .deleteItem(id)
            .then((data) => {
                this.setState({
                    loading: false,
                });

                // splice out the deleted object
                var idx = this.state.data.findIndex((o) => o.id === id);
                if (idx > -1) {
                    let list = [...this.state.data];
                    list.splice(idx, 1);
                    this.setState({
                        data: list,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        return (
            <Card
                style={{ width: "100%" }}
                title="Templates"
                extra={
                    <Button type="primary" icon="plus" onClick={this.handleAdd}>
                        Add
                    </Button>
                }
            >
                <Table columns={this.columns} rowKey={(x) => x.id} dataSource={this.state.data} pagination={this.state.pagination} loading={this.state.loading} onChange={this.handleTableChange} />
            </Card>
        );
    }
}

export default Templates;
