import React from "react";
import { Layout } from "antd";
const { Content } = Layout;
// import TopNav from "./TopNav";

class SimpleLayout extends React.Component {
    
      render() {
        return (
            <Layout>
                
                <Layout>
                    <Content className="container">
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>

        )};
}

export default SimpleLayout;