import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { Card } from "antd";
import NewsletterStatistics from 'components/charts/NewsletterStatistics';


const Dashboard = () => {
        
    return (
        <Card title={<IntlMessages id="manage.dashboard.title"/>}>
            <NewsletterStatistics />
        </Card>
    );
}

export default Dashboard;