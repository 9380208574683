import React, { Component } from "react";
import { userService } from "services/UserService.js";
import { roleService } from "services/RoleService.js";
import IntlMessages from "util/IntlMessages";
import { Table, Button, Icon, notification } from "antd";
import moment from "moment";
import { Messaging } from "util/LangProvider/locales/en_US_notifications";
import RolePickerModal from "components/modals/RolePickerModal";

class UserRoles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            roles: [],
            allRoles: [],
        };

        this.rolePickerModal = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    // get the inital data (user roles and all roles)
    loadData = () => {
        this.setState({
            loading: true,
        });

        Promise.all([userService.getUserRoles(this.props.data.id), roleService.get()])
            .then((response) => {
                this.setState({
                    roles: response[0],
                    allRoles: response[1],
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleAddRole = () => {
        this.rolePickerModal.current.handleToggle();
    };

    handleDeleteUserRole = (roleid) => {
        if (!window.confirm("Are you sure you want to remove this role?")) return;

        userService
            .deleteRoleFromUser(this.props.data.id, roleid)
            .then((data) => {
                notification.success({
                    message: Messaging.success(),
                    description: "The role has been removed",
                });

                // splice out the deleted object
                var idx = this.state.roles.findIndex((o) => o.roleId === roleid);
                if (idx > -1) {
                    let list = [...this.state.roles];
                    list.splice(idx, 1);
                    this.setState({
                        roles: list,
                    });
                }
            })
            .catch((err) => {
                notification.error({
                    message: Messaging.error(),
                    description: err,
                });
                console.log(err);
            });
    };

    render() {
        return (
            <div>
                <div className="mb-2 mr-auto float-right">
                    <Button type="primary" onClick={this.handleAddRole}>
                        <IntlMessages id="manage.users.addrole" />
                    </Button>
                </div>

                <Table
                    className="clear-both"
                    dataSource={this.state.roles}
                    loading={this.state.loading}
                    rowKey={(x) => x.roleId}
                    pagination={{}}
                    columns={[
                        {
                            title: "Role",
                            dataIndex: "roleName",
                            render: (t, o) => <span>{o.roleName}</span>,
                        },
                        {
                            title: "Started",
                            dataIndex: "startDate",
                            render: (t, o) => <span>{o.startDate && moment(o.startDate).format("LL")}</span>,
                        },
                        {
                            title: "Expiry",
                            dataIndex: "expiryDate",
                            render: (t, o) => <span>{o.expiryDate && moment(o.expiryDate).format("LL")}</span>,
                        },
                        {
                            title: "Created",
                            dataIndex: "createdOn",
                            render: (t, o) => <span>{o.createdOn && moment(o.createdOn).format("LL")}</span>,
                        },
                        {
                            title: "",
                            dateIndex: "roleId",
                            width: "50px",
                            render: (t, o) => (
                                <span onClick={() => this.handleDeleteUserRole(o.roleId)}>
                                    <Icon type="delete" theme="twoTone" />
                                </span>
                            ),
                        },
                    ]}
                />

                <RolePickerModal ref={this.rolePickerModal} userId={this.props.data.id} roles={this.state.allRoles} reload={this.loadData} />
            </div>
        );
    }
}

export default UserRoles;
