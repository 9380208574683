import React from "react";
import LeadEdit from "components/leads/LeadEdit";

class Lead extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            id: 0,
        };
    }

    componentWillMount() {
        // get ids if any
        let { userid, id } = this.props.match.params;

        // update - get the data for this id
        if (userid === undefined) this.props.history.push("/");

        this.setState({
            userId: userid,
        });

        if (id !== undefined) {
            id = parseInt(id, 10);
            this.setState({
                id: id,
            });
        }
    }

    handleSubmit = () => {
        this.props.history.push(`/manage/user/${this.state.userId}#clientlist`);
    };

    render() {
        return <LeadEdit userId={this.state.userId} id={this.state.id} onSubmit={this.handleSubmit} />;
    }
}

export default Lead;
