import React, { Component } from "react";
import { userNewsletterService } from "services/UserNewsletterService.js";
import { userService } from "services/UserService.js";
import { Form, Input, notification, Switch, Radio, Button } from "antd";
import Uploader from "components/ui/Uploader";
import { Messaging } from "util/LangProvider/locales/en_US_notifications";

class UserNewsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };

        this.rolePickerModal = React.createRef();

        // get user info
        this.authUser = userService.getUserInfo();
    }

    componentDidMount() {
        this.loadInfo();
    }

    loadInfo = () => {
        this.setState({
            loading: true,
            id: this.props.data.id,
        });

        userNewsletterService
            .get(this.props.data.id)
            .then((data) => {
                this.setState({
                    newsletterLogo: data.newsletterLogo,
                });

                this.props.form.setFieldsValue({
                    newsletterLogoType: data.newsletterLogoType.toString(),
                    newsletterName: data.newsletterName,
                    websiteUrl: data.websiteUrl,
                    facebookUrl: data.facebookUrl,
                    twitterUrl: data.twitterUrl,
                    linkedInUrl: data.linkedInUrl,
                    instagramUrl: data.instagramUrl,
                    newsletterBody: data.newsletterBody,
                    useAutoNewsletter: data.useAutoNewsletter,
                    useProfilePhoto: data.useProfilePhoto,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleUploadSuccess = (u) => {
        this.setState({
            newsletterLogo: u,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // create the object
                values.id = this.state.id;
                values.userId = this.state.id;
                values.updatedBy = this.authUser.id;
                values.newsletterLogo = this.state.newsletterLogo;

                this.setState({
                    loading: true,
                });

                userNewsletterService
                    .post(values)
                    .then((data) => {
                        // show a woo-hoo
                        notification.success({
                            message: Messaging.success(),
                            description: "You have successfully updated your settings",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                    <Form.Item label="NewsletterLogoType">
                        {getFieldDecorator(
                            "newsletterLogoType",
                            {}
                        )(
                            <Radio.Group buttonStyle="solid" onChange={this.handleLogoChange}>
                                <Radio.Button value="0">IdeaFit Logo</Radio.Button>
                                <Radio.Button value="1">Custom Logo</Radio.Button>
                                <Radio.Button value="2">No Logo</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {this.props.form.getFieldValue("newsletterLogoType") === "0" && (
                        <Form.Item label="Logo">
                            <img src="https://www.ideafit.com/wp-content/uploads/2023/10/idea-logo-300px.png" alt="logo" />
                        </Form.Item>
                    )}

                    {this.props.form.getFieldValue("newsletterLogoType") === "1" && (
                        <Form.Item label="Logo">
                            <Uploader src={this.state.newsletterLogo} handleUploadSuccess={this.handleUploadSuccess} />
                        </Form.Item>
                    )}

                    <Form.Item label="Title">
                        {getFieldDecorator("newsletterName", {
                            rules: [{ required: false, message: "Please give your newsletter a title" }],
                        })(<Input placeholder="My amazing newsletter" />)}
                    </Form.Item>

                    <Form.Item label="Website">
                        {getFieldDecorator("websiteUrl", {
                            rules: [{ required: false, type: "url", message: "Please enter a valid URL" }],
                        })(<Input placeholder="https://mysite.com" />)}
                    </Form.Item>

                    <Form.Item label="Facebook Url">
                        {getFieldDecorator("facebookUrl", {
                            rules: [{ required: false, type: "url", message: "Please enter your facebook profile URL" }],
                        })(<Input placeholder="https://facebook.com/myuser" />)}
                    </Form.Item>

                    <Form.Item label="Twitter Url">
                        {getFieldDecorator("twitterUrl", {
                            rules: [{ required: false, type: "url", message: "Please enter your twitter profile URL" }],
                        })(<Input placeholder="https://twitter.com/myuser" />)}
                    </Form.Item>

                    <Form.Item label="LinkedIn Url">
                        {getFieldDecorator("linkedInUrl", {
                            rules: [{ required: false, type: "url", message: "Please enter your LinkedIn profile URL" }],
                        })(<Input placeholder="https://www.linkedin.com/in/myuser/" />)}
                    </Form.Item>

                    <Form.Item label="Instagram Url">
                        {getFieldDecorator("instagramUrl", {
                            rules: [{ required: false, type: "url", message: "Please enter your Instagram profile URL" }],
                        })(<Input placeholder="https://www.instagram.com/myuser/" />)}
                    </Form.Item>

                    <Form.Item label="Intro Paragraph">{getFieldDecorator("newsletterBody", {})(<Input.TextArea rows={5} />)}</Form.Item>

                    <Form.Item label="Use Auto Posts">{getFieldDecorator("useAutoNewsletter", { valuePropName: "checked" })(<Switch />)}</Form.Item>

                    <Form.Item label="Show Profile Photo">{getFieldDecorator("useProfilePhoto", { valuePropName: "checked" })(<Switch onClick={this.handleSwitch2} />)}</Form.Item>

                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrappedForm = Form.create({ name: "newsletterForm" })(UserNewsletter);
export default WrappedForm;
