import React from 'react';
import { Form, Input, Modal } from 'antd';
import Uploader from 'components/ui/Uploader';


class ContentEditModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            visible: false,
            loading: false,
            data: {},
            title: "",
            body: "",
            postUrl: "",
            thumbUrl: ""
        };
    }

    toggleModal = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    loadData = (o) => {
        // if we're creating a new entry
        if(o == null) {
            o = {
                id: 0,
                postUrl: "",
                title: "",
                body: "",
                thumbUrl: ""
            }
        }

        this.setState({
            data: o,
            postUrl: o.postUrl,
            thumbUrl: o.thumbUrl,
            title: o.title,
            body: o.body,
            visible: true
        });

    }

    // from the uploader widget
    handleUploadSuccess = u => {        
        this.setState({
            thumbUrl: u
        });
    }

    handleOk = (o) => {
        // notify parent
        if(typeof this.props.onUpdate === "function") {
            
            let o = this.state.data;
            o.thumbUrl = this.state.thumbUrl;
            o.postUrl = this.state.postUrl;
            o.title = this.state.title;
            o.body = this.state.body;

            this.props.onUpdate(o);
            this.toggleModal();
        }
    }

    render() {
        
        return (
            <Modal 
                title="Content Edit" 
                visible={this.state.visible}
                onCancel={this.toggleModal}
                onOk={this.handleOk}
                >
                    
                <Form.Item label="Logo">
                    <Uploader src={this.state.thumbUrl} handleUploadSuccess={this.handleUploadSuccess} />
                </Form.Item>
                            
                <Form.Item label="Title">
                    <Input id="title" value={this.state.title} onChange={(o) => this.setState({ title: o.target.value })} placeholder="title" />
                </Form.Item>

                <Form.Item label="Url">
                    <Input id="postUrl" value={this.state.postUrl} onChange={(o) => this.setState({ postUrl: o.target.value })} placeholder="http://someurl.com" />
                </Form.Item>

                <Form.Item label="Excerpt">
                    <Input.TextArea id="body" value={this.state.body} onChange={(o) => this.setState({ body: o.target.value })} placeholder="excerpt" rows={6} />
                </Form.Item>

            </Modal>
        );
    }
}

export default ContentEditModal;