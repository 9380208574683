import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Divider, Card, Icon, Tag, notification, Tooltip, Popconfirm } from "antd";
import { newsletterService } from "services/NewsletterService";
import { userService } from "services/UserService.js";
import moment from "moment";
import EmailListModal from "components/modals/EmailListModal";
import { Messaging } from "util/LangProvider/locales/en_US_notifications";
import { useParams, useHistory } from "react-router-dom";

const UserNewsletters = (props) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState();
    const [loading, setLoading] = useState(false);
    const [testId, setTestId] = useState();
    const emailListModal = useRef();
    const authUser = userService.getUserInfo();
    const { id } = useParams();
    const userId = id; // ugly but works

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Publish Date",
            dataIndex: "datePublish",
            render: (text, record) => <span>{moment(record.datePublish).format("LLLL")}</span>,
        },
        {
            title: "Published",
            dataIndex: "isPublished",
            align: "center",
            render: (text, record) => (
                <div className="text-center">
                    {record.isSkipped && <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96" />}
                    {!record.isSkipped && record.isPublished && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
                    {!record.isSkipped && !record.isPublished && <Tag color="volcano">pending</Tag>}
                </div>
            ),
        },
        {
            title: "Actions",
            align: "center",
            key: "id",
            render: (text, record) => (
                <div className="text-right">
                    {record.isSkipped && (
                        <span>
                            <Tooltip title="resend">
                                <Popconfirm title="Are you sure you want to resend this? It'll be sent in a few minutes." onConfirm={() => handleResend(record)} okText="Yes" cancelText="No">
                                    <Icon type="redo" style={{ color: "#1890FF" }} />
                                </Popconfirm>
                            </Tooltip>
                            <Divider type="vertical" />
                        </span>
                    )}

                    <Tooltip title="send proof">
                        <span className="pointer" onClick={() => handleMail(record.id)}>
                            <Icon type="mail" theme="twoTone" />
                        </span>
                    </Tooltip>

                    {record.isPublished && (
                        <span>
                            <Divider type="vertical" />
                            <Tooltip title="stats">
                                <span className="pointer" onClick={() => history.push(`/manage/newsletterstats/${record.id}/${record.userId}`)}>
                                    <Icon type="fund" theme="twoTone" />
                                </span>
                            </Tooltip>
                        </span>
                    )}
                    {!record.isPublished && (
                        <span>
                            <Divider type="vertical" />
                            <Tooltip title="delete">
                                <span className="pointer" onClick={() => handleDelete(record.id)}>
                                    <Icon type="delete" theme="twoTone" />
                                </span>
                            </Tooltip>
                        </span>
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
        loadGrid();
        console.log(userId);
    }, []);

    const loadGrid = (params = {}) => {
        setLoading(true);

        newsletterService
            .get(authUser.portalId, userId)
            .then((data) => {
                setData(data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        setPagination(pager);

        loadGrid({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
        });
    };

    const handleResend = (o) => {
        setLoading(true);

        newsletterService
            .resend(o.id)
            .then((data) => {
                notification.success({
                    message: Messaging.success(),
                    description: "The newsletter will be resent in a few minutes",
                });

                loadGrid();
            })
            .catch((err) => {
                notification.error({
                    message: Messaging.error(),
                    description: "That didn't work. Please try again",
                });
            })
            .finally(() => setLoading(false));
    };

    const handleMail = (id) => {
        setTestId(id);

        // load up the email list picker
        emailListModal.current.handleToggle();
    };

    const handleEmailListSubmitted = (arrayOfEmails) => {
        setLoading(true);

        newsletterService
            .test(testId, userId, true, arrayOfEmails)
            .then((data) => {
                notification.success({
                    message: Messaging.success(),
                    description: "Your test was sent. Check your inbox.",
                });
            })
            .catch((err) => {
                notification.error({
                    message: Messaging.error(),
                    description: "That didn't work. Please try again",
                });
            })
            .finally(() => setLoading(false));
    };

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to remove this item?")) return;

        newsletterService
            .deleteItem(id)
            .then((data) => {
                // splice out the deleted object
                var idx = data.findIndex((o) => o.id === id);
                if (idx > -1) {
                    let list = [...data];
                    list.splice(idx, 1);
                    setData(list);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <Card style={{ width: "100%" }} title="Newsletters">
            <Table
                columns={columns}
                rowKey={(x) => x.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                expandedRowRender={(record) => (
                    <div style={{ margin: 0 }}>
                        {record.message && (
                            <div>
                                <strong>Message: {record.message}</strong>
                            </div>
                        )}
                        Delivered: {record.delivered}
                        <br />
                        Clicked: {record.clicked}
                        <br />
                        Opened: {record.opened}
                    </div>
                )}
            />

            <EmailListModal ref={emailListModal} email={authUser.email} onSubmit={handleEmailListSubmitted} />
        </Card>
    );
};

export default UserNewsletters;
