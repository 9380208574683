import React from "react";
import IntlMessages from "util/IntlMessages";
import { userService } from "services/UserService.js";
import { Button, Card, Spin } from "antd";
import UserProfile from "components/users/UserProfile";

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            userData: {},
            loading: false,
        };

        this.authUser = userService.getUserInfo();
    }

    componentDidMount() {
        this.getExistingItem();
    }

    getExistingItem = () => {
        let { id } = this.props.match.params;

        // if id doesn't exist, then leave
        if (id === undefined) {
            this.props.history.push("/manage/users");
        }

        this.setState({
            id: id,
        });

        this.setState({
            loading: true,
        });

        userService
            .getById(id)
            .then((data) => {
                this.setState({
                    userData: data,
                });
            })
            .catch((err) => {
                console.log(err);
                this.props.history.push("/manage/users");
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <Card
                title={<IntlMessages id="manage.users.title" />}
                extra={
                    <span>
                        <Button color="btn btn-light" onClick={() => this.props.history.push("/manage/users")} title="View All Users">
                            View All Users
                        </Button>
                    </span>
                }
            >
                {this.state.loading && <Spin size="large" />}

                {!this.state.loading && <UserProfile data={this.state.userData} />}
            </Card>
        );
    }
}

export default Users;
