import React from 'react';
import { Table, Button, Input, Modal, Icon } from 'antd';
import { wordpressService } from 'services/WordpressService';

const { Search } = Input;

class ContentSearchModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            visible: false,
            loading: false,
            data: [],
            pagination: {}
        };

        this.columns = [
            {
              title: 'Title',
              dataIndex: 'title'
            },
            {
                title: 'Actions',
                align: 'center',
                key: 'id',
                render: (text, record) => (
                    <div className="text-center">
                      <span onClick={() => this.handleSelect(record)}><Icon type="select" theme="outlined" /></span>
                    </div>
                ),
            }
        ];

    }

    toggleModal = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    handleSearch = (keyword) => {

        if(keyword === "")
            return;

        this.setState({
            loading: true
        })
        
        wordpressService.search(keyword)
            .then(data => {
                this.setState({
                    data: data
                })
            })
            .catch(err => console.log(err))
            .finally(() => {                
                this.setState({
                    loading: false
                })
            });

    }


    handleSelect = (o) => {
        // notify parent
        if(typeof this.props.onSelect === "function") {
            // get the details of the post
            wordpressService.get(o.id)
                .then(data => {
                    
                    let regex = /(<([^>]+)>)/ig;


                    // create a return object
                    let newObj = {
                        id: data.id,
                        date: data.date,
                        slug: data.slug,
                        postUrl: data.link,
                        title: data.title.rendered,
                        body: data.excerpt.rendered.replace(regex, ""),
                        featuredMedia: data.featured_media,
                        thumbUrl: ""
                    }

                    // now get the thumbnail if there is one
                    if(data.featured_media) {
                        wordpressService.getMedia(data.featured_media)
                            .then(m => {

                                // get the first resized image for the thumbnail if it exists
                                if(m.media_details && m.media_details.sizes && m.media_details.sizes.length > 0)
                                    newObj.thumbUrl = m.media_details.sizes[0].source_url.replace(/ /g, "%20");
                                else // otherwise take the main image
                                    newObj.thumbUrl = m.source_url.replace(/ /g, "%20");

                            })
                            .catch(err => console.log(err));
                    }

                    this.props.onSelect(newObj);
                    this.toggleModal();
                })
                .catch(err => console.log(err));
        }
    }

    render() {
        
        return (
            <Modal 
                title="Content Search" 
                visible={this.state.visible} 
                onCancel={this.toggleModal}
                footer={[
                    <Button type="primary" key="close" onClick={this.toggleModal}>
                      Close
                    </Button>
                  ]}
                >

                    <div className="pb-1">
                        <Search
                            placeholder="search text"
                            onSearch={value => this.handleSearch(value)}
                            style={{ width: 200 }} />
                    </div>

                    <div>
                        <Table
                            columns={this.columns}
                            rowKey={x => x.id}
                            dataSource={this.state.data}
                            pagination={this.state.pagination}
                            loading={this.state.loading}
                            onChange={this.handleTableChange}
                            size="small"
                        />
                    </div>

            </Modal>
        );
    }
}

export default ContentSearchModal;