import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainApp from "pages/app/index";
import ManageApp from "pages/manage/index";
import Oauth from "pages/misc/Oauth";
import ThirdPartySignin from "pages/misc/ThirdPartySignin";
import Unsubscribe from "pages/misc/Unsubscribe";
import { IntlProvider } from "react-intl";
import { userService } from "services/UserService.js";
import AppLocale from "util/LangProvider";
import asyncComponent from "util/AsyncComponent";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/thirdpartysignin",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

class App extends Component {
    componentWillMount() {
        // if dev, then add class to change the bg color
        if (window.location.href.indexOf("localhost") > -1) document.getElementById("root").className += " root-dev";
    }

    render() {
        // get authUser from localStorage
        let authUser = userService.getUserInfo();

        const { match, location } = this.props;

        if (location.pathname === "/") {
            if (authUser === null) {
                // if we have an code, then they are trying to login
                if (location.search.indexOf("code") > -1) {
                    return <Redirect to={"/oauth" + location.search} />;
                } else {
                    return <Redirect to={"/thirdpartysignin"} />;
                }
            } else {
                return <Redirect to={"/app/newsletter"} />;
            }
        } else if (location.pathname.indexOf("manage") > -1 && (authUser == null || !authUser.newsletterAdminAccess)) {
            // permissions check
            return <Redirect to={"/"} />;
        }

        const currentAppLocale = AppLocale["en"];

        return (
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <div id="app-main" className="app-main">
                    <Switch>
                        <RestrictedRoute authUser={authUser} path={`${match.url}app`} component={MainApp} />
                        <RestrictedRoute authUser={authUser} path={`${match.url}manage`} component={ManageApp} />
                        <Route path="/oauth" component={Oauth} />
                        <Route path="/thirdpartysignin" component={ThirdPartySignin} />
                        <Route path="/unsubscribe" component={Unsubscribe} />
                        <Route component={asyncComponent(() => import("pages/misc/404"))} />
                    </Switch>
                </div>
            </IntlProvider>
        );
    }
}

export default App;
