import React from 'react';
import { Modal, Input } from 'antd';

class EmailListModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            list: this.props.email
        };
    } 

    // select form field event
    handleChanges = (val) => {
        this.setState({ list: val.target.value });
    }
    
    // shows/hides the modal (called from parent or here)
    handleToggle = () => {        
        this.setState({
            modal: !this.state.modal
        });
    }
    
    // submit event
    handleOk = () => {

        let listOfEmails = this.state.list.replace(/,/g, "\n").split("\n");
        let filtered = listOfEmails.filter((o) => {
            return o !== "";
        })

        // send list back to the parent        
        if(this.props.onSubmit)
            this.props.onSubmit(filtered);
        
    }

    render() {
        
        return (
            <Modal
                title="Enter Emails"
                visible={this.state.modal} 
                className="modal-sm"
                onOk={this.handleOk}
                onCancel={this.handleToggle}
                >
                
                <div className="row mb-3">
                    <div className="col-sm-12">
                        <label className="mb-2">List of Emails (one per line)</label>
                        <div>
                            <Input.TextArea id="emails" rows={6} onChange={this.handleChanges} value={this.state.list}></Input.TextArea>                            
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default EmailListModal;