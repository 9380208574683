import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Table, Divider, Card, Icon, Tag } from "antd";
import { leadService } from "services/LeadService";
import LeadImporter from "components/leads/LeadImporter";

class UserLeads extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pagination: {},
            loading: false,
            layout: "grid",
        };

        this.leadImporter = React.createRef();
    }

    componentDidMount() {
        if (this.props.data.id === "")
            // userid
            return;

        this.loadGrid();
    }

    loadGrid = (params = {}) => {
        this.setState({
            loading: true,
        });

        leadService
            .get(this.props.data.id)
            .then((data) => {
                this.setState({
                    data: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    renderStatus(param) {
        switch (param) {
            case 1:
                return <Tag color="volcano">unsubscribed</Tag>;
            case 2:
                return <Tag color="#333333">global opt-out</Tag>;
            default:
                return <Tag color="green">subscribed</Tag>;
        }
    }

    handleAdd = (evt) => {
        if (this.props.isAdmin) this.props.history.push(`/manage/lead/${this.props.data.id}`);
        else this.props.history.push("/app/lead");
    };

    handleEdit = (id) => {
        if (this.props.isAdmin) this.props.history.push(`/manage/lead/${this.props.data.id}/${id}`);
        else this.props.history.push(`/app/lead/${id}`);
    };

    handleDelete = (id) => {
        if (!window.confirm("Are you sure you want to remove this item?")) return;

        leadService
            .deleteItem(id)
            .then((data) => {
                this.setState({
                    loading: false,
                });

                // splice out the deleted object
                var idx = this.state.data.findIndex((o) => o.id === id);
                if (idx > -1) {
                    let list = [...this.state.data];
                    list.splice(idx, 1);
                    this.setState({
                        data: list,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        if (this.state.layout === "grid") {
            return (
                <Card
                    style={{ width: "100%" }}
                    title="Client List"
                    extra={
                        <span>
                            <Button type="primary" icon="plus" onClick={this.handleAdd}>
                                Add
                            </Button>
                            <Button type="secondary" icon="import" className="ml-1" onClick={() => this.setState({ layout: "import" })}>
                                Import
                            </Button>
                        </span>
                    }
                >
                    <Table
                        rowKey={(x) => x.id}
                        dataSource={this.state.data}
                        loading={this.state.loading}
                        columns={[
                            {
                                title: "First",
                                dataIndex: "firstName",
                                sorter: (a, b) => {
                                    return a.firstName.localeCompare(b.firstName);
                                },
                            },
                            {
                                title: "Last",
                                dataIndex: "lastName",
                                sorter: (a, b) => {
                                    return a.lastName.localeCompare(b.lastName);
                                },
                            },
                            {
                                title: "Email",
                                dataIndex: "email",
                            },
                            {
                                title: "Sending",
                                dataIndex: "isActive",
                                align: "center",
                                render: (text, record) => (
                                    <div className="text-center">
                                        {record.isActive && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
                                        {!record.isActive && <Icon type="stop" theme="twoTone" twoToneColor="#aa3333" />}
                                    </div>
                                ),
                            },
                            {
                                title: "Status",
                                dataIndex: "status",
                                align: "center",
                                render: (text, record) => <div>{this.renderStatus(record.status)}</div>,
                            },
                            {
                                title: "Actions",
                                align: "center",
                                key: "id",
                                render: (text, record) => (
                                    <div className="text-center">
                                        <span onClick={() => this.handleEdit(record.id)} className="pointer">
                                            <Icon type="edit" theme="twoTone" />
                                        </span>

                                        {!record.isDefault && (
                                            <span>
                                                <Divider type="vertical" />
                                                <span onClick={() => this.handleDelete(record.id)} className="pointer">
                                                    <Icon type="delete" theme="twoTone" />
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Card>
            );
        } else {
            // import
            return (
                <Card
                    title="Client List"
                    extra={
                        <Button type="primary" icon="backward" onClick={() => this.setState({ layout: "grid" })}>
                            Back
                        </Button>
                    }
                >
                    <LeadImporter ref={this.leadImporter} onSuccess={this.loadGrid} userId={this.props.data.id} />
                </Card>
            );
        }
    }
}

export default withRouter(UserLeads);
