import React, { useState } from "react";
import JWPlayer from "@jwplayer/jwplayer-react";
import { Modal } from "antd";

export default function IntroVideo({ useModal = false }) {
    const isSeen = localStorage.getItem("introVideo");
    const [showModal, setShowModal] = useState(true);
    const player = (
        <div className="jwplayer-wrapper">
            <JWPlayer
                library="https://cdn.jwplayer.com/players/X8GUNB3J-s77QAnTi.js"
                playlist="https://cdn.jwplayer.com/v2/sites/Ud2KmOq1/media/X8GUNB3J/playback.json?recommendations_playlist_id=1KJgysHb"
                config={{ key: "d9/kh7W6NRtSbliP5+d8wvAvXrq+oRGtj/Si+vODDBcxiXSitjhuIbDz3Igqptw5" }}
            />
        </div>
    );

    // if useModal and not seen, show modal
    if (useModal && !isSeen)
        return (
            <Modal
                title="Welcome to the Client News"
                visible={showModal}
                width={800}
                footer={null}
                onCancel={() => {
                    localStorage.setItem("introVideo", true);
                    setShowModal(false);
                }}
            >
                {player}
            </Modal>
        );
    else if (!useModal) return player;
    else return <></>;
}
