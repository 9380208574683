import axios from 'axios';
import { authHeader } from 'util/AuthHeader';
import { handleResponse } from 'util/HandleResponse';

// define public methods
export const userNewsletterService = {
    get,
    post
};

axios.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === 401) {
            localStorage.removeItem('user_info');
            window.location.reload(true);
        }
        return Promise.reject(error);
   }
);

function get(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {

        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/userNewsletter/${id}`;

        axios.get(url, requestOptions)
            .then(handleResponse)
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            })
    })        
}

function post(data) {

    const requestOptions = { headers: authHeader() };
    
    return new Promise((resolve, reject) => {
    
        axios.post(`${process.env.REACT_APP_WEBAPI_URL}/api/userNewsletter/${data.id}`, data, requestOptions)
            .then(handleResponse)
            .then(res => {                    
                resolve(res);
            })
            .catch(res => {
                reject('Error');
            });
    });        
}
