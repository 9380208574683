import axios from 'axios';

// define public methods
export const wordpressService = {
    search,
    get,
    getMedia
};

function search(keyword){
    
    return new Promise((resolve, reject) => {
        
        let url = `${process.env.REACT_APP_WORDPRESS_API}search?api_token=${process.env.REACT_APP_WORDPRESS_KEY}&search=${keyword}`;

        axios.get(url)            
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            });
    });
}

function get(id){
    
    return new Promise((resolve, reject) => {
        
        let url = `${process.env.REACT_APP_WORDPRESS_API}posts/${id}?api_token=${process.env.REACT_APP_WORDPRESS_KEY}`;

        axios.get(url)            
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            });
    });
}

function getMedia(id){
    
    return new Promise((resolve, reject) => {
        
        let url = `${process.env.REACT_APP_WORDPRESS_API}media/${id}?api_token=${process.env.REACT_APP_WORDPRESS_KEY}`;

        axios.get(url)            
            .then(res => {
                let item = res.data;
                resolve(item);
            })
            .catch(res => {
                reject('Error');
            });
    });
}