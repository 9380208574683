import React from 'react';
import { withRouter } from "react-router-dom";
import { Button, Card, Form, Input, Switch, Radio } from "antd";
import { leadService } from 'services/LeadService';



class LeadEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    componentDidMount() {

        // update - get the data for this id
        if(this.props.id !== 0) {
            this.setState({
                loading: true
            });

            leadService.getById(this.props.userId, this.props.id)
                .then(data => {                    

                    this.props.form.setFieldsValue({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        isActive: data.status === 1 ? false : data.isActive, // inactivate them if unsubbed
                        status: data.status.toString()
                    });
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.setState({
                        loading: false
                    })
                });
        }
        else {
            this.props.form.setFieldsValue({
                isActive: true,
                status: "0"
            });
        }
    }

    // inactivate them if unsubbed
    handleStatusChange = e => {
        if(e.target.value === "1")
            this.props.form.setFieldsValue({
                isActive: false                
            });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);

                // append the object
                values.id = this.props.id;
                values.userId = this.props.userId;
                values.status = parseInt(values.status, 10);
                values.isActive = values.status === 1 ? false : values.isActive;

                this.setState({
                    loading: true
                });

                leadService.post(values)
                    .then(data => {
                        if(this.props.onSubmit)
                            this.props.onSubmit();
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false
                        });
                    });

            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        
        return (
            <Card style={{ width:'100%' }}
                title="Lead"
                extra={<Button type="primary" icon="backward" onClick={() => this.props.history.goBack()}>Back</Button>}>
                
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                    <Form.Item label="First Name">
                        {getFieldDecorator('firstName', {
                            rules: [{ required: true, message: 'Please enter a first name' }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Last Name">
                        {getFieldDecorator('lastName', {
                            rules: [{ required: true, message: 'Please enter a last name' }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Please enter an email address' }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Sending Enabled">
                        {getFieldDecorator('isActive', { valuePropName: 'checked' })(<Switch />)}
                    </Form.Item>

                    <Form.Item label="Status">
                        {getFieldDecorator('status', { })(
                            <Radio.Group buttonStyle="solid" onChange={this.handleStatusChange}>
                                <Radio.Button value="0">Subscribed</Radio.Button>
                                <Radio.Button value="1">Unsubscribed</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    
                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>Submit</Button>
                    </Form.Item>
                </Form>
    
            </Card>
        );
    }
}

const WrappedForm = Form.create({ name: 'leadEditForm' })(withRouter(LeadEdit));
export default WrappedForm;