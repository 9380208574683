import React from "react";
import { Card, Button } from "antd";
import IntroVideo from "../../components/ui/IntroVideo";

class GettingStarted extends React.Component {
    resetTutorial = () => {
        localStorage.removeItem("tutorial");
        window.location.href = "/";
    };

    render() {
        return (
            <>
                <Card
                    style={{ width: "100%" }}
                    title="Getting Started"
                    bordered={false}
                    extra={
                        <Button type="primary" onClick={this.resetTutorial}>
                            Reset Tutorial
                        </Button>
                    }
                >
                    <ul>
                        <li className="mb-4">
                            <h2>Build your email</h2>
                            Add your logo, newsletter title, social channels on the <em>Settings</em> page.
                        </li>

                        <li className="mb-4">
                            <h2>Manage your recipients</h2>
                            You can manually edit or bulk import a list of them on the <em>Client List</em> page.
                        </li>

                        <li className="mb-4">
                            <h2>Review upcoming or current newsletter</h2>
                            On the <em>Current Newsletter</em> page, you can preview your upcoming newsletter. The most recently sent newsletter will display until the next one is ready.
                        </li>

                        <li className="mb-4">
                            <h2>Review previous newsletters and analytics</h2>
                            View a list of previously sent newsletters and view delivery stats on the <em>Newsletter Archive</em>.
                        </li>
                    </ul>
                    <div className="jwplayer-wrapper">
                        <IntroVideo />
                    </div>
                </Card>
            </>
        );
    }
}

export default GettingStarted;
