import axios from "axios";
import { authHeader } from "util/AuthHeader";
import { handleResponse } from "util/HandleResponse";

// define public methods
export const userService = {
    getUserInfo,
    verify,
    get,
    getById,
    updateSelf,
    post,
    put,
    getUserRoles,
    addRoleToUser,
    deleteRoleFromUser,
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            localStorage.removeItem("user_info");
            window.location.reload(true);
        }
        return Promise.reject(error);
    }
);

// get the current user from local storage
function getUserInfo() {
    try {
        return localStorage.getItem("user_info") && JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user_info"))));
    } catch (err) {
        console.log(err);
        localStorage.removeItem("user_info");
        return null;
    }
}

function verify(alias, idToken) {
    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/verify?alias=` + alias + "&token=" + idToken + "&context=newsletter";

        axios
            .get(url)
            .then(handleResponse)
            .then((res) => {
                let items = res.data;
                resolve(items);
            })
            .catch((res) => {
                reject(res);
            });
    });
}

function get(portalid, page, pagesize, sorted, keyword) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user?portalid=${portalid}`;

        /*if(filtered.length > 0) {
            let filteredData = filtered.reduce((filteredSoFar, nextFilter) => {
                return filteredSoFar + nextFilter.id + "^" + nextFilter.value + "|";
              }, "");
            
              url += "&keyword=" + escape(filteredData);
        } */

        if (keyword.length > 0) url += "&keyword=" + escape(keyword);

        // if(roleid > 0)
        //      url += "&roleid=" + roleid;

        if (page > 0 && pagesize > 0) url += "&page=" + page + "&pagesize=" + pagesize;

        if (sorted.length > 0) url += "&sort=" + sorted[0].id + "&desc=" + (sorted[0].desc === "descend").toString();

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getById(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/` + id;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function updateSelf() {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/me`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // copy over the token
                res.data.token = oldUser.token;

                // update the user in local storage with the new data
                localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));

                resolve();
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function post(data) {
    const requestOptions = { headers: authHeader() };

    // add new
    if (data.id === 0) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${process.env.REACT_APP_WEBAPI_URL}/api/user`, data, requestOptions)
                .then(handleResponse)
                .then((res) => {
                    let post = res.data;
                    resolve(post);
                })
                .catch((res) => {
                    reject("Error");
                });
        });
    }

    // update
    return new Promise((resolve, reject) => {
        axios
            .put(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + data.id, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                resolve(data);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

/*
    // update one field in the user record
    Inputs:
        userid
        data: {
            name,
            systemName,
            value, 
            fieldType
        }
*/
function put(userid, data) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios
            .put(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + userid, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // copy over the token
                res.data.token = oldUser.token;

                // update the user in local storage with the new data
                localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));
                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function getUserRoles(id) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_WEBAPI_URL}/api/user/` + id + `/roles`;

        axios
            .get(url, requestOptions)
            .then(handleResponse)
            .then((res) => {
                let item = res.data;
                resolve(item);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function addRoleToUser(userId, roleId, startDate, expiryDate) {
    const requestOptions = { headers: authHeader() };

    let data = {
        userId,
        roleId,
        startDate,
        expiryDate,
    };

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + userId + `/role`, data, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // if user is me, then update userinfo
                if (oldUser.id === userId) {
                    // copy over the token
                    res.data.token = oldUser.token;

                    // update the user in local storage with the new data
                    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));
                }

                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}

function deleteRoleFromUser(userId, roleId) {
    const requestOptions = { headers: authHeader() };

    return new Promise((resolve, reject) => {
        axios
            .delete(`${process.env.REACT_APP_WEBAPI_URL}/api/user/` + userId + `/role/${roleId}`, requestOptions)
            .then(handleResponse)
            .then((res) => {
                // get the token
                let oldUser = userService.getUserInfo();

                // if user is me, then update userinfo
                if (oldUser.id === userId) {
                    // copy over the token
                    res.data.token = oldUser.token;

                    // update the user in local storage with the new data
                    localStorage.setItem("user_info", window.btoa(encodeURIComponent(JSON.stringify(res.data))));
                }

                resolve(res);
            })
            .catch((res) => {
                reject("Error");
            });
    });
}
