import React from "react";
import { Layout, Menu, Icon, Dropdown } from "antd";
import { NavLink } from "react-router-dom";
import { userService } from "services/UserService";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import IntlMessages from "util/IntlMessages";

const { Content, Header, Sider } = Layout;

class MasterLayout extends React.Component {
    state = {
        collapsed: false,
        joyrideRun: false,
        joyrideCache: localStorage.getItem("tutorial") || "0",
        joyrideSteps: [
            {
                target: ".joyride-1",
                title: "Build your email",
                content: "Add your logo, newsletter title, social channels.",
                placement: "auto",
                disableBeacon: true,
            },
            {
                target: ".joyride-2",
                title: "Manage your recipients",
                content: "You can manually edit or bulk import a list of them.",
                placement: "auto",
                disableBeacon: true,
            },
            {
                target: ".joyride-3",
                title: "Review upcoming or current newsletter",
                content: "Preview your upcoming newsletter. The most recently sent newsletter will display until the next one is ready.",
                placement: "auto",
                disableBeacon: true,
            },
            {
                target: ".joyride-4",
                title: "Review previous newsletters and analytics",
                content: "View a list of previously sent newsletters and view delivery stats.",
                placement: "auto",
                disableBeacon: true,
            },
        ],
    };

    componentDidMount() {
        this.setState({
            joyrideRun: this.state.joyrideCache === "0",
        });
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onUserNavSelect = () => {
        this.setState({
            userNav: !this.state.userNav,
        });
    };

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        //
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && this.state.joyrideRun) {
            // Need to set our running state to false, so we can restart if we click start again.
            this.setState({ joyrideRun: false });
            localStorage.setItem("tutorial", "1");
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
    };

    userSignOut(e) {
        localStorage.removeItem("user_info");
        window.location.href = "//www.ideafit.com/wp-login.php?action=logout";
        e.preventDefault();
        return false;
    }

    render() {
        this.authUser = userService.getUserInfo();

        let userMenu = (
            <Menu>
                <Menu.Item>
                    <img className="user-avatar border-0 size-40" src={this.authUser.thumbnailUrl} alt="User" />
                    <div className="user-detail ml-2">
                        <h4 className="user-name mb-0">{this.authUser.fullName}</h4>
                        <small>{this.authUser.accountType}</small>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <a className="dropdown-item text-muted" href="https://www.ideafit.com/wp-admin/profile.php" target="_blank" rel="noopener noreferrer">
                        <Icon type="user" className="mr-1" />
                        <IntlMessages id="popup.profile" />
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <NavLink to="/app/settings" className="dropdown-item text-muted">
                        <Icon type="setting" className="mr-1" />
                        <IntlMessages id="popup.setting" />
                    </NavLink>
                </Menu.Item>
                <Menu.Item>
                    <a className="dropdown-item text-muted" href="/" onClick={this.userSignOut}>
                        <Icon type="logout" className="mr-1" />
                        <IntlMessages id="popup.logout" />
                    </a>
                </Menu.Item>
            </Menu>
        );

        return (
            <Layout>
                <Sider trigger={null} collapsible collapsed={this.state.collapsed} collapsedWidth="0">
                    <Menu theme="light" mode="inline">
                        <Menu.Item key="1" className="joyride-3">
                            <NavLink to="/app/newsletter">
                                <Icon type="mail" />
                                <span>Current Newsletter</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="2" className="joyride-1">
                            <NavLink to="/app/settings">
                                <Icon type="setting" />
                                <span>Settings</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="3" className="joyride-2">
                            <NavLink to="/app/leads">
                                <Icon type="team" />
                                <span>Client List</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="4" className="joyride-4">
                            <NavLink to="/app/newsletters">
                                <Icon type="profile" />
                                <span>Newsletter Archive</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="5">
                            <NavLink to="/app/gettingstarted">
                                <Icon type="info-circle" />
                                <span>Getting Started?</span>
                            </NavLink>
                        </Menu.Item>
                    </Menu>

                    {this.authUser.newsletterAdminAccess && (
                        <Menu theme="light" mode="inline">
                            <Menu.Divider />

                            <Menu.Item key="6">
                                <NavLink to="/manage/dashboard">
                                    <Icon type="home" />
                                    <span>Admin</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="7">
                                <NavLink to="/manage/newsletters">
                                    <Icon type="mail" />
                                    <span>Manage Newsletters</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="8">
                                <NavLink to="/manage/templates">
                                    <Icon type="code" />
                                    <span>Templates</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="9">
                                <NavLink to="/manage/users">
                                    <Icon type="team" />
                                    <span>Users</span>
                                </NavLink>
                            </Menu.Item>
                        </Menu>
                    )}
                </Sider>
                <Layout>
                    <Header className="navbar">
                        <a href="https://www.ideafit.com" target="_blank" rel="noopener noreferrer" className="navbar-logo-container">
                            <img src="https://www.ideafit.com/wp-content/uploads/2023/07/idea-logo-light.svg" className="navbar-logo" alt="logo" />
                        </a>
                        <Icon className="trigger s-16x" type={this.state.collapsed ? "menu-unfold" : "menu-fold"} onClick={this.toggle} />

                        <div className="ml-auto mr-0">
                            <Icon type="question-circle" className="s-16x mr-3 pt-2 pointer" onClick={() => this.setState({ joyrideRun: true, stepIndex: 1, joyrideCache: "0" })} />

                            <Dropdown trigger={["click"]} overlay={userMenu}>
                                <img alt="..." src={this.authUser.thumbnailUrl} className="pointer user-avatar mb-2 size-30" />
                            </Dropdown>
                        </div>
                    </Header>
                    <Content className="container">
                        {this.props.children}

                        {parseInt(this.state.joyrideCache, 10) === 0 && (
                            <Joyride
                                steps={this.state.joyrideSteps}
                                run={this.state.joyrideRun}
                                disableScrolling={true}
                                showSkipButton={true}
                                callback={this.handleJoyrideCallback}
                                continuous
                                locale={{ last: "Done" }}
                            />
                        )}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default MasterLayout;
