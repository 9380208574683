import React from 'react';
import { userService } from 'services/UserService.js';
import UserLeads from 'components/users/UserLeads';


class Leads extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.authUser = userService.getUserInfo();
    }

    componentDidMount() {

    }


    render() {
        
        return (
            <UserLeads data={this.authUser} isAdmin={false} />
        );
    }
}

export default Leads;