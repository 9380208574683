import React from "react";
import LeadEdit from "components/leads/LeadEdit";
import { userService } from "services/UserService.js";

class Lead extends React.Component {
    constructor(props) {
        super(props);
        this.authUser = userService.getUserInfo();

        this.state = {
            id: 0,
        };
    }

    componentWillMount() {
        // get id if any
        let { id } = this.props.match.params;

        // update - get the data for this id
        if (id !== undefined) {
            id = parseInt(id, 10);
            this.setState({
                id: id,
            });
        }
    }

    handleSubmit = () => {
        this.props.history.push("/app/leads");
    };

    render() {
        return <LeadEdit userId={this.authUser.id} id={this.state.id} onSubmit={this.handleSubmit} />;
    }
}

export default Lead;
