import React from "react";
import { Form, Input, Button, Avatar, Card, DatePicker, Select, List, Icon } from "antd";
import { newsletterService } from "services/NewsletterService";
import { templateService } from "services/TemplateService";
import { userService } from "services/UserService.js";
import ContentSearchModal from "components/modals/contentSearchModal";
import ContentEditModal from "components/modals/contentEditModal";
import moment from "moment";

const { Option } = Select;

class Newsletter extends React.Component {
    constructor(props) {
        super(props);

        // get user info
        this.authUser = userService.getUserInfo();

        this.state = {
            id: 0,
            loading: false,
            templates: [],
            searchVisible: false,
            content: [],
        };

        this.searchModal = React.createRef();
        this.editModal = React.createRef();
    }

    componentDidMount() {
        this.setState({
            loading: true,
        });

        templateService
            .get(this.authUser.portalId)
            .then((data) => {
                this.setState({
                    templates: data,
                });

                // see if we're prepopulating
                this.getExistingItem();
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    // preload
    getExistingItem = () => {
        // get id if any
        let { id } = this.props.match.params;

        // update - get the data for this id
        if (id !== undefined) {
            id = parseInt(id, 10);
            this.setState({
                id: id,
            });

            newsletterService
                .getById(id)
                .then((data) => {
                    this.props.form.setFieldsValue({
                        name: data.name,
                        datePublish: moment(data.datePublish),
                        templateId: data.templateId.toString(),
                    });

                    this.setState({
                        content: JSON.parse(data.postJson),
                    });
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    };

    // delete a post
    handleRemovePost = (id) => {
        // splice out the deleted object
        var idx = this.state.content.findIndex((o) => o.id === id);
        if (idx > -1) {
            let list = [...this.state.content];
            list.splice(idx, 1);
            this.setState({
                content: list,
            });
        }
    };

    // save
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);

                // create the object
                values.id = this.state.id;
                values.templateId = parseInt(values.templateId, 10);
                values.isAuto = true;
                values.isPublished = false;
                values.postJson = JSON.stringify(this.state.content);
                values.portalId = this.authUser.portalId;
                values.updatedBy = this.authUser.id;

                this.setState({
                    loading: true,
                });

                newsletterService
                    .post(values)
                    .then((data) => {
                        this.props.history.push("/manage/newsletters");
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        });
    };

    // a post is selected from the modal
    handleModalSelect = (o) => {
        var joined = this.state.content.concat(o);

        this.setState({
            content: joined,
        });
    };

    handleModalUpdate = (o) => {
        let list = [...this.state.content];

        // if original content, then add to the list
        if (o.id === 0) {
            o.id = Math.floor(Math.random() * 100000) * -1; // randomize number
            list.push(o);
        }

        // update the edited post
        var idx = this.state.content.findIndex((c) => c.id === o.id);
        if (idx > -1) {
            // update
            list[idx].title = o.title;
            list[idx].body = o.body;
        }

        this.setState({
            content: list,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Card
                style={{ width: "100%" }}
                title="Newsletter"
                extra={
                    <Button type="primary" icon="backward" onClick={() => this.props.history.goBack()}>
                        Back
                    </Button>
                }
            >
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                    <Form.Item label="Title">
                        {getFieldDecorator("name", {
                            rules: [{ required: true, message: "Please give it a title" }],
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item label="Publish Date/Time">
                        {getFieldDecorator("datePublish", {
                            rules: [{ required: true, message: "Please enter a date/time" }],
                        })(<DatePicker showTime placeholder="Select Date/Time" />)}
                    </Form.Item>

                    <Form.Item label="Template">
                        {getFieldDecorator("templateId", {
                            rules: [{ required: true, message: "Please choose a template" }],
                        })(
                            <Select>
                                {this.state.templates.map((o) => (
                                    <Option key={o.id}>{o.name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Search">
                        <Button onClick={() => this.searchModal.current.toggleModal()} className="mr-1">
                            Choose Content
                        </Button>
                        <Button onClick={() => this.editModal.current.loadData()}>Create Content</Button>
                        <ContentSearchModal ref={this.searchModal} onSelect={this.handleModalSelect} />
                        <ContentEditModal ref={this.editModal} onUpdate={this.handleModalUpdate} />

                        <List
                            itemLayout="vertical"
                            dataSource={this.state.content}
                            renderItem={(o) => (
                                <List.Item
                                    actions={[
                                        <span className="pointer" key="list-item-delete" onClick={() => this.handleRemovePost(o.id)}>
                                            <Icon type="delete" theme="twoTone" />
                                        </span>,
                                        <span className="pointer" key="list-item-edit" onClick={() => this.editModal.current.loadData(o)}>
                                            <Icon type="edit" theme="twoTone" />
                                        </span>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar size="large" shape="square" src={o.thumbUrl} />}
                                        title={
                                            <a href={o.postUrl} target="_blank" rel="noopener noreferrer">
                                                {o.title}
                                            </a>
                                        }
                                        description={
                                            <span>
                                                <p>{o.body}</p>
                                                <br />
                                                <a href={o.postUrl} target="_blank" rel="noopener noreferrer">
                                                    read more &raquo;
                                                </a>
                                            </span>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

const WrappedForm = Form.create({ name: "newsletterForm" })(Newsletter);
export default WrappedForm;
